import { Flex, Img } from '@chakra-ui/react';

import ArtistIcon from 'assets/icons/artist.svg';
import ClockIcon from 'assets/icons/clock-white.svg';
import DateIcon from 'assets/icons/date.svg';
import ExpiryIcon from 'assets/icons/expiry.svg';
import FilesIcon from 'assets/icons/file-header.svg';
import MixTypeIcon from 'assets/icons/mix-type.svg';
import NameIcon from 'assets/icons/name-icon.svg';
import FolderIcon from 'assets/icons/name.svg';
import ProjectIcon from 'assets/icons/project.svg';
import ReviewHeaderIcon from 'assets/icons/review-header.svg';
import ReviewIcon from 'assets/icons/review.svg';
import SizeIcon from 'assets/icons/size.svg';
import SongIcon from 'assets/icons/song.svg';
import TeamIcon from 'assets/icons/team.svg';
import TransferIcon from 'assets/icons/transfer.svg';
import ProfileIcon from 'assets/icons/user.svg';
import VersionIcon from 'assets/icons/version.svg';
import FileIcon from 'assets/icons/white-file-icon.svg';

function IconWithLabel({ icon, label } : { icon: string, label: string }): JSX.Element {
  return (
    <Flex>
      <Img color='white' height='20px' width='20px' src={icon} marginRight='7px' />
      {label}
    </Flex>
  );
}
export function FolderHeader(): JSX.Element {
  return <IconWithLabel icon={FolderIcon} label='Folder' />;
}

export function NameHeader(): JSX.Element {
  return <IconWithLabel icon={NameIcon} label='Name' />;
}

export function FromHeader(): JSX.Element {
  return <IconWithLabel icon={ProfileIcon} label='From' />;
}

export function RestoredByHeader(): JSX.Element {
  return <IconWithLabel icon={ProfileIcon} label='Restored by' />;
}

export function ArtistHeader(): JSX.Element {
  return <IconWithLabel icon={ArtistIcon} label='Artist' />;
}

export function ProjectHeader(): JSX.Element {
  return <IconWithLabel icon={ProjectIcon} label='Project' />;
}

export function DateHeader(): JSX.Element {
  return <IconWithLabel icon={DateIcon} label='Date' />;
}

export function ReviewHeader(): JSX.Element {
  return <IconWithLabel icon={ReviewIcon} label='Review' />;
}

export function SongHeader(): JSX.Element {
  return <IconWithLabel icon={SongIcon} label='Song' />;
}

export function VersionHeader(): JSX.Element {
  return <IconWithLabel icon={VersionIcon} label='Version' />;
}

export function SizeHeader(): JSX.Element {
  return <IconWithLabel icon={SizeIcon} label='Size' />;
}

export function DateRestoredHeader(): JSX.Element {
  return <IconWithLabel icon={DateIcon} label='Date Restored' />;
}
export function StatusHeader(): JSX.Element {
  return <IconWithLabel icon={ReviewIcon} label='Status' />;
}

export function ExpiryHeader(): JSX.Element {
  return <IconWithLabel icon={ExpiryIcon} label='Expiry' />;
}

export function TeamHeader(): JSX.Element {
  return <IconWithLabel icon={TeamIcon} label='Team' />;
}

export function TransferHeader(): JSX.Element {
  return <IconWithLabel icon={TransferIcon} label='Transfers' />;
}

export function TransferIDHeader(): JSX.Element {
  return <IconWithLabel icon={TransferIcon} label='Transfer ID' />;
}

export function FilesHeader(): JSX.Element {
  return <IconWithLabel icon={FilesIcon} label='Files' />;
}

export function FileHeader(): JSX.Element {
  return <IconWithLabel icon={FileIcon} label='File' />;
}

export function RecipientsHeader(): JSX.Element {
  return <IconWithLabel icon={TeamIcon} label='Recipients' />;
}

export function MixType(): JSX.Element {
  return <IconWithLabel icon={MixTypeIcon} label='Mix Type' />;
}

export function Review(): JSX.Element {
  return <IconWithLabel icon={ReviewHeaderIcon} label='Review' />;
}

export function Technical(): JSX.Element {
  return <IconWithLabel icon={ProjectIcon} label='Technical' />;
}

export function Duration(): JSX.Element {
  return <IconWithLabel icon={ClockIcon} label='Duration' />;
}

export function FileSize(): JSX.Element {
  return <IconWithLabel icon={SizeIcon} label='File Size' />;
}

export function RecipientEmail(): JSX.Element {
  return <IconWithLabel icon={ProfileIcon} label='Recipient' />;
}

export function EmailTemplateHeader(): JSX.Element {
  return <IconWithLabel icon={VersionIcon} label='Email' />;
}

export function EmailStatusHeader(): JSX.Element {
  return <IconWithLabel icon={ReviewIcon} label='Status' />;
}
