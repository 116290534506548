import { CopyIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex, FormControl, FormErrorMessage, Heading, IconButton, Image, Input, InputGroup, InputRightElement,
  Text, useClipboard, useToast,
} from '@chakra-ui/react';
import { isAxiosError } from 'axios';
import { useEffect } from 'react';
import {
  FormProvider, RegisterOptions, useForm,
} from 'react-hook-form';
import QRCode from 'react-qr-code';
import { useEffectOnce } from 'react-use';

import { transferServerApi } from 'api/transfersServerApi';
import TwoFactorIcon from 'assets/icons/twofactor.svg';
import { AsyncWrapper } from 'components/AsyncWrapper';
import AsyncButton from 'components/form/AsyncButton';
import useAxios from 'hooks/useAxios';

interface IVerifyForm {
  token: string;
}

export default function TwoFactorVerification(): JSX.Element {
  const formMethods = useForm<IVerifyForm>();
  const {
    onCopy, hasCopied, setValue,
  } = useClipboard('');
  const toast = useToast();
  const {
    register, handleSubmit, formState, setError,
  } = formMethods;
  const { errors } = formState;
  const tokenInputRegisterOptions: RegisterOptions<IVerifyForm, 'token'> = {
    required: 'Token is required',
    minLength: {
      value: 6,
      message: 'Token must be 6 digits',
    },
    maxLength: {
      value: 6,
      message: 'Token must be 6 digits',
    },
    pattern: {
      value: /^[0-9]*$/,
      message: 'Token must be a number',
    },
  };
  const [generateReq, sendGenerateReq] = useAxios<{ uri: string, secret: string }>({
    url: '/file-api/otpa/generate-secret',
    method: 'POST',
  }, true);

  useEffectOnce(() => {
    void sendGenerateReq();
  });

  useEffect(() => {
    if (!generateReq.data?.secret) {
      return;
    }
    setValue(generateReq.data.secret);
  }, [generateReq.data?.secret, setValue]);

  const onSubmit = async (data: IVerifyForm): Promise<void> => {
    const body = {
      secret: generateReq.data!.secret,
      code: data.token,
    };
    try {
      await transferServerApi.POST('/api-v2/otpa/enroll', {
        body,
      });
      void toast({
        title: 'Success',
        description: 'Two factor authentication enabled',
        status: 'success',
      });
    } catch (e) {
      if (!isAxiosError(e)) {
        throw e;
      }
      if (e.response?.status === 400) {
        setError('token', { type: 'manual', message: 'Invalid token' });
      }
    }
  };

  return (
    <Flex
      flexDirection='row'
      width='80%'
      gap='80px'
      alignItems='center'
      minW='800px'
    >
      <Flex
        flexDirection='column'
        width='600px'
      >
        <Flex
          alignItems='center'
          gap='10px'
          marginBottom='20px'
        >
          <Image
            src={TwoFactorIcon}
            height='25px'
          />
          <Heading>2-Step Verification</Heading>
        </Flex>

        <Flex gap='10px' flexDir='column'>
          <Text>Two-factor authentication provides extra protection for your account by requiring a special code to login into a new device.</Text>
          <Text mt='15px'>To enable two-factor authentication:</Text>
          <Text>1. Scan the QR code on the right using your authenticator app.</Text>
          <Text>2. Enter the token generated by your authenticator app in the field below to confirm and activate two-factor authentication.</Text>
        </Flex>
        <Text />
        <Flex
          flexDirection='column'
        >
          <Text marginTop='25px' marginBottom='10px'>Verify Token</Text>
          <FormControl isInvalid={!!errors.token?.message}>
            <FormProvider {...formMethods}>
              <form>
                <Flex gap='15px'>
                  <Flex direction='column' gap='10px'>
                    <Input border='1px dashed #A0AEC0' placeholder='Enter token here' height='55px' w='400px' {...register('token', tokenInputRegisterOptions)} />
                    <FormErrorMessage>
                      <Text pt='5px' textColor='gold.1000'>{errors.token?.message} </Text>
                    </FormErrorMessage>
                  </Flex>
                  <AsyncButton
                    formState={formState}
                    height='55px'
                    width='150px'
                    spinAfterPromiseResolvedSuccess={false}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Verify
                  </AsyncButton>
                </Flex>
              </form>
            </FormProvider>
          </FormControl>
        </Flex>
      </Flex>
      <AsyncWrapper requests={[generateReq]}>
        <Flex direction='column'>
          <Box padding='12px' bg='white' borderRadius='10px' marginBottom='20px'>
            <QRCode value={generateReq.data?.uri || ''} height='250px' />
          </Box>
          <Flex gap='8px'>
            <InputGroup>
              <Input
                value={generateReq.data?.secret}
                readOnly
                opacity={0.5}
              />
              <InputRightElement>
                <IconButton
                  aria-label='Copy text'
                  icon={<CopyIcon />}
                  onClick={onCopy}
                >{hasCopied ? 'Copied!' : 'Copy'}
                </IconButton>
              </InputRightElement>
            </InputGroup>
          </Flex>
        </Flex>
      </AsyncWrapper>
    </Flex>
  );
}
