import {
  Flex,
  ModalContentProps,
  Text,
  useToken,
} from '@chakra-ui/react';
import { useState } from 'react';
import { When } from 'react-if';
import AsyncSelect from 'react-select/async';

import { ArchiveProjectOverview } from './ArchiveProjectOverview';
import { transferServerApi } from 'api/transfersServerApi';
import DateComponent from 'components/DateComponent';
import CustomErrorBoundary from 'components/error/CustomErrorBoundary';
import { BasicModalHeader, ModalFrame } from 'components/modal-frame/ModalFrame';
import { Upload } from 'pages/transfers/upload-transfer-pages/components/Upload';
import { ProjectCTRowDto } from 'types/api';

const DEFAULT_TAKE = 5;

const content: ModalContentProps = {
  maxW: '700px',
};

function OptionLabel(project: ProjectCTRowDto): JSX.Element {
  return (
    <Text fontSize='12px' color='white' cursor='pointer'>
      <Text as='span' fontWeight='400' mr='4px'>{project.project.id}: {project.project.name}</Text>
      <Text as='span' fontWeight='500' mr='4px'>|</Text>
      <Text as='span' fontWeight='500' mr='8px'>{project.artist}</Text>
      <Text as='span' fontWeight='500' color='gray.500'>
        <DateComponent date={project.date} />
      </Text>
    </Text>
  );
}

export function ArchivesUploadModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean,
  onClose: () => void,
}): JSX.Element {
  const [gray400, gray700, gray800, gray900] = useToken('colors', ['gray.400', 'gray.700', 'gray.800', 'gray.900']);
  const [selectedProject, setSelectedProject] = useState<ProjectCTRowDto | null>(null);

  const getPromiseOptions = async (inputValue: string): Promise<ProjectCTRowDto[]> => {
    const { data } = await transferServerApi.GET('/api-v2/projects', {
      params: {
        query: {
          take: DEFAULT_TAKE,
          q: inputValue,
        },
      },
    });
    return data!;
  };

  return (
    <ModalFrame
      hasCloseButton
      style={{ content }}
      header={<BasicModalHeader title='Upload Archives' />}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <Flex
        direction='column'
        gap='8px'
      >
        <AsyncSelect
          cacheOptions
          defaultOptions
          unstyled
          onChange={(option) => setSelectedProject(option)}
          placeholder='Search and select a project name, project number or artist...'
          loadOptions={getPromiseOptions}
          getOptionLabel={(option) => `${option.project.id}: ${option.project.name} - ${option.artist}`}
          getOptionValue={(option) => `${option.project.id}`}
          value={selectedProject}
          formatOptionLabel={OptionLabel}
          styles={{
            control: (provided) => ({
              ...provided,
              background: gray800,
              borderRadius: '8px',
              padding: '18px',
              boxShadow: 'none',
              borderWidth: '1px',
              borderColor: gray400,
            }),
            container: (provided) => ({
              ...provided,
              color: gray400,
            }),
            menu: (provided) => ({
              ...provided,
              background: gray900,
              marginTop: '10px',
              borderRadius: '8px',
              padding: '18px',
              boxShadow: 'none',
              borderWidth: '1px',
              borderColor: gray400,
            }),
            option: (provided) => ({
              ...provided,
              'display': 'flex',
              'alignItems': 'center',
              'borderRadius': '4px',
              'padding': '10px',
              'height': '32px',
              ':hover': {
                background: gray700,
                cursor: 'pointer',
              },
            }),
          }}
        />
        <When condition={selectedProject !== null}>
          <Flex
            direction='column'
            gap='15px'
          >
            <CustomErrorBoundary>
              <ArchiveProjectOverview projectId={selectedProject?.project.id} />
            </CustomErrorBoundary>
            <Upload
              onUploadBegin={() => setSelectedProject(null)}
              uploadToProject={{
                projectId: Number(selectedProject?.project.id),
                isArchive: true,
                selectedRecipients: [],
                requireMFA: false,
                enableSamply: false,
              }}
              projectMetadata={{
                name: selectedProject?.project.name,
                artist: selectedProject?.artist ?? undefined,
              }}
            />
          </Flex>
        </When>
      </Flex>
    </ModalFrame>
  );
}
