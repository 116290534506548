import { useToast } from '@chakra-ui/react';
import { uniqBy } from 'lodash';
import {
  useCallback, useState,
} from 'react';

import { DownloadError } from '../StudioError';
import { transferServerApi } from 'api/transfersServerApi';
import { IChromeDownloadModalProps } from 'components/ChromeDownloadModal';
import { IChromeSecurityNoticeProps } from 'components/google-context-modal/ChromeSecurityNoticeModal';
import { ISmsVerifyModal } from 'components/SmsVerifyModal';
import { authenticateDownload, startDownload } from 'hooks/transfer/transferManager';
import { FileDto } from 'types/api';
import { parseError } from 'utils/error';

async function getRootDirHandle(): Promise<FileSystemDirectoryHandle> {
  const handle = await window.showDirectoryPicker({
    mode: 'readwrite',
  });

  return handle;
}

interface IDownloadParams {
  blobs: FileDto[] | undefined,
  verificationCode ?: string,
  verificationPhone ?: string,
  artist ?: string,
  byPassChromeSecurity ?: boolean,
}

export function useAzureDownload(): {
  downloadBlobs: (params: IDownloadParams) => Promise<void>;
  smsModalProps: ISmsVerifyModal,
  chromeSecurityNotificationProps: IChromeSecurityNoticeProps,
  chromeDownloadModalProps: IChromeDownloadModalProps,
} {
  const [files, setFiles] = useState<FileDto[]>([]);
  const [openSmsModal, setOpenSmsModal] = useState(false);
  const [openChromeSecurityModal, setOpenChromeSecurityModal] = useState(false);
  const [openChromeDownloadModal, setOpenChromeDownloadModal] = useState(false);
  const [transferId, setTransferId] = useState<number | null>(null);

  const toast = useToast();

  const downloadBlobs = useCallback(async (params: IDownloadParams): Promise<void> => {
    if (window.showDirectoryPicker === undefined) {
      setOpenChromeDownloadModal(true);
      return;
    }

    if (!params.blobs || params.blobs.length === 0) {
      throw new DownloadError('No files to download', 'No files to download');
    }

    const blobs = params.blobs.filter((c) => !c.FileName.endsWith('.ini'));
    setFiles(blobs);
    const uniqueTransfers = uniqBy(blobs, 'TransferId');
    if (uniqueTransfers.length !== 1) throw new DownloadError('Can only download files from a single transfer at a time', `Expected 1 transfer, got ${uniqueTransfers.length}`);

    const byPassChromeSecurity = params.byPassChromeSecurity || localStorage.getItem('disabled_chrome_security_notice') === 'true';
    if (!byPassChromeSecurity) {
      setOpenChromeSecurityModal(true);
      return;
    }

    const rootDirHandle = await getRootDirHandle();

    const uniqueTransferId = uniqueTransfers[0].TransferId;
    setTransferId(uniqueTransferId);

    const transfer = await transferServerApi.GET('/api-v2/transfers/{transferId}', {
      params: {
        path: {
          transferId: Number(uniqueTransferId),
        },
      },
    });

    const requiresSms = transfer.data?.DownloadRequiresMFA && (!params.verificationCode || !params.verificationPhone);
    if (requiresSms) {
      setOpenSmsModal(true);
      return;
    }

    const auth = await authenticateDownload(uniqueTransferId, params.verificationCode, params.verificationPhone);
    if (auth === undefined) {
      throw new DownloadError('Failed to authenticate download', 'Failed to authenticate download');
    }
    setOpenSmsModal(false);

    const currentToastId = toast({
      description: 'Starting download...',
      isClosable: false,
      status: 'info',
      duration: null,
    });

    try {
      await startDownload({
        auth,
        blobs,
        concurrency: 4,
        abortController: new AbortController(),
        dirHandle: rootDirHandle,
        onProgress: (progress) => {
          const progressText = `Downloading${params.artist ? ` ${params.artist}` : ''} ${progress.percentText} - ${progress.progressStatus}`;
          if (progress.isActive) {
            toast.update(currentToastId, {
              description: progressText,
              isClosable: false,
              duration: null,
              status: 'loading',
            });
          }
        },
        operation: 'download',
      });

      toast.update(currentToastId, {
        description: 'Download Complete',
        status: 'success',
        isClosable: true,
      });
    } catch (err: any) {
      const { message } = parseError(err);
      const errorMessage = err.message?.includes('An operation that depends on state cached')
        || err.message?.includes('file or directory could not be found at the time an operation')
        ? `Some of the file names are too long for the destination folder. Please select a target folder with a shorter path. \n ${message}`
        : message;

      if (currentToastId) {
        toast.close(currentToastId);
      }

      toast({
        description: `Download Failed: ${errorMessage}`,
        status: 'error',
        duration: null,
        isClosable: true,
      });
      throw err;
    }
  }, [toast]);

  return {
    downloadBlobs,
    smsModalProps: {
      isOpen: openSmsModal,
      onClose: () => {
        setOpenSmsModal(false);
      },
      onVerify: async (code, phone) => {
        if (files.length > 0) {
          await downloadBlobs({
            blobs: files,
            verificationCode: code,
            verificationPhone: phone,
            byPassChromeSecurity: true,
          });
        }
      },
      transferId: transferId as number,
    },
    chromeSecurityNotificationProps: {
      isOpen: openChromeSecurityModal,
      onClose: () => {
        setOpenChromeSecurityModal(false);
      },
      onNext: async () => {
        setOpenChromeSecurityModal(false);
        await downloadBlobs({
          blobs: files,
          byPassChromeSecurity: true,
        });
      },
    },
    chromeDownloadModalProps: {
      isOpen: openChromeDownloadModal,
      onClose: () => {
        setOpenChromeDownloadModal(false);
      },
    },
  };
}
