import {
  Flex, Heading, Img, FormLabel as Label, LightMode, Switch, Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  Else, If, Then, When,
} from 'react-if';
import { useParams } from 'react-router';

import { TransferTaskList } from './components/TransferTaskList';
import WorkOrderDropdown from './components/WorkOrderDropdown';
import { HoldStatusBanner } from '../files-page/components/HoldStatusBanner';
import { ProjectOverview } from '../project-overview-page/components/ProjectOverview';
import { transferServerApi } from 'api/transfersServerApi';
import PhoneIcon from 'assets/icons/phone.svg';
import SamplyIcon from 'assets/icons/samply.svg';
import WarningIcon from 'assets/icons/warning.svg';
import { AsyncWrapper } from 'components/AsyncWrapper';
import AuthorizeDisplay from 'components/AuthorizeDisplay';
import QuerySuspense from 'components/QuerySuspense';
import RecipientCheckBox from 'components/RecipientCheckBox';
import { ChakraAlert } from 'components/WarningAlert';
import { Roles } from 'contexts/user/claims';
import { useApiQuery } from 'hooks/useApiQuery';
import useAxios from 'hooks/useAxios';
import useUserContext from 'hooks/useUserContext';
import { Upload } from 'pages/transfers/upload-transfer-pages/components/Upload';
import { Contacts } from 'types/api';

function LabelWithSwitch({
  icon, label, isChecked, onChange,
}: { icon: string, label: string, isChecked: boolean, onChange: () => void }): JSX.Element {
  return (
    <Flex width='100%' justifyContent='space-between'>
      <Flex align='center' gap='7px'>
        <Img color='white' height='20px' width='20px' src={icon} />
        <Label fontWeight='700' size='14px' lineHeight='20px' m='0'>{label}</Label>
      </Flex>
      <Flex h='20px' align='center'>
        <LightMode>
          <Switch colorScheme='red' size='sm' isChecked={isChecked} onChange={onChange} />
        </LightMode>
      </Flex>
    </Flex>
  );
}

export function UploadTransferPage(): JSX.Element {
  const { projectId } = useParams();
  const { isStudioMember } = useUserContext();

  const [selectedRecipients, setSelectedRecipients] = useState<string[]>([]);
  const [requireMFA, setRequireMFA] = useState(false);
  const [enableSamply, setEnableSamply] = useState(!!isStudioMember);
  const [showRecipientError, setShowRecipientError] = useState(false);
  const [showWorkOrderError, setShowWorkOrderError] = useState(false);
  const [workOrderIdentifier, setWorkOrderIdentifier] = useState<string | undefined>(undefined);

  const [contacts] = useAxios<Contacts[]>(`/file-api/contacts/${projectId}`);

  const projectDetailsQuery = useApiQuery<{ project: { ProjectIdentifier: string }, client: { CreditLimit: number } | null }, any, any>({
    queryKey: [projectId],
    queryFn: () => transferServerApi.GET('/api-v2/projects/{projectNumber}', {
      params: { path: { projectNumber: Number(projectId) } },
    }),
  });

  const holdStatusQuery = useApiQuery({
    queryKey: [projectDetailsQuery.apiResult?.data?.project.ProjectIdentifier, workOrderIdentifier],
    queryFn: () => transferServerApi.GET('/api-v2/projects/{projectIdentifier}/hold-status', {
      params: {
        path: {
          projectIdentifier: projectDetailsQuery.apiResult?.data?.project.ProjectIdentifier,
        },
        query: {
          workOrderIdentifier,
        },
      },
    }),
    enabled: !!projectDetailsQuery.apiResult?.data?.project.ProjectIdentifier && isStudioMember,
  });

  useEffect(() => {
    if (selectedRecipients.length > 0 && isStudioMember) setShowRecipientError(false);
  }, [selectedRecipients, isStudioMember]);

  useEffect(() => {
    if (workOrderIdentifier) setShowWorkOrderError(false);
  }, [workOrderIdentifier]);

  return (
    <AsyncWrapper requests={[contacts]}>
      <Flex direction='column' gap='50px'>
        <Heading fontWeight='700px' fontSize='20px' lineHeight='31px'>New Transfer Upload</Heading>
        <When condition={
          projectDetailsQuery.apiResult?.data?.project.ProjectIdentifier
          && isStudioMember
          && projectDetailsQuery.apiResult?.data.client
}
        >
          <QuerySuspense queries={[holdStatusQuery.queryResult]}>
            <HoldStatusBanner
              holdStatus={holdStatusQuery.apiResult?.data!}
              isUpload
            />
          </QuerySuspense>
        </When>
        <ProjectOverview projectId={projectId} />
        <QuerySuspense queries={[projectDetailsQuery.queryResult]}>
          <If condition={(isStudioMember && projectDetailsQuery.apiResult?.data.client) || !isStudioMember}>
            <Then>
              <Flex direction='column' gap='15px'>
                <AuthorizeDisplay role={Roles.StudioMember}>
                  <Flex
                    direction='row'
                    border='1px solid'
                    borderColor={showRecipientError ? 'gold.1000' : 'gray.400'}
                    borderRadius='15px'
                    p='25px'
                    gap='100px'
                    mb='50px'
                  >
                    <Flex direction='column' gap='15px' minW='400px'>
                      <Label fontWeight='700' size='14px' lineHeight='16px' m='0'>Settings</Label>
                      <LabelWithSwitch
                        icon={PhoneIcon}
                        label='SMS Verification'
                        isChecked={requireMFA}
                        onChange={() => setRequireMFA(!requireMFA)}
                      />
                      <When condition='samplyIntegrationEnabled'>
                        <LabelWithSwitch
                          icon={SamplyIcon}
                          label='Create Samply Link'
                          isChecked={enableSamply}
                          onChange={() => setEnableSamply(!enableSamply)}
                        />
                      </When>
                      <When condition={Number(projectDetailsQuery.apiResult?.data?.client?.CreditLimit) === 0}>
                        <Flex direction='column' gap='15px' mt='30px'>
                          <Label fontWeight='700' size='14px' lineHeight='16px' m='0'>Work Order</Label>
                          <WorkOrderDropdown projectIdentifier={projectDetailsQuery.apiResult?.data?.project.ProjectIdentifier} onSelect={setWorkOrderIdentifier} />
                        </Flex>
                      </When>
                    </Flex>
                    <Flex direction='column' gap='15px' flex='1 0 300px' maxW='500px'>
                      <Label fontWeight='700' size='14px' lineHeight='16px' m='0'>Recipients</Label>
                      <Flex flexFlow='column wrap' height='80px' columnGap='50px' rowGap='5px'>
                        {contacts.data?.map((recipient: Contacts) => (
                          <RecipientCheckBox
                            contact={recipient}
                            selectedRecipients={selectedRecipients}
                            setSelectedRecipients={setSelectedRecipients}
                            key={recipient.ContactIdentifier}
                          />
                        ))}
                      </Flex>
                    </Flex>
                  </Flex>
                </AuthorizeDisplay>
                <When condition={showRecipientError}>
                  <Text textStyle='paragraphReg' color='gold.1000' ml='26px' display='flex' alignItems='baseline' gap='7px'>
                    <Img height='15px' width='15px' src={WarningIcon} />
                    In order to upload files, you must first select at least one recipient.
                  </Text>
                </When>
                <When condition={showWorkOrderError}>
                  <Text textStyle='paragraphReg' color='gold.1000' ml='26px' display='flex' alignItems='baseline' gap='7px'>
                    <Img height='15px' width='15px' src={WarningIcon} />
                    In order to upload files, you must first select a work order.
                  </Text>
                </When>
              </Flex>
              <Upload
                creditLimit={projectDetailsQuery.apiResult?.data?.client?.CreditLimit}
                uploadToProject={{
                  projectId: Number(projectId),
                  selectedRecipients,
                  requireMFA,
                  enableSamply,
                  isArchive: false,
                  workOrderIdentifier,
                  setShowRecipientError: isStudioMember ? setShowRecipientError : undefined,
                  setShowWorkOrderError: isStudioMember ? setShowWorkOrderError : undefined,
                }}
              />
              <TransferTaskList />
            </Then>
            <Else>
              <ChakraAlert alertStatus='warning' title='Cannot Upload' description="Project doesn't have a client, please contact coordinator." />
            </Else>
          </If>
        </QuerySuspense>
      </Flex>
    </AsyncWrapper>
  );
}
