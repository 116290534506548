import {
  Box,
  Flex,
  Select, Spacer, Text,
  useToast,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAsync } from 'react-use';

import { studioServerApi } from 'api/studioServerApi';
import { AsyncWrapper } from 'components/AsyncWrapper';
import AsyncButton from 'components/form/AsyncButton';
import { BasicModalHeader, ModalFrame } from 'components/modal-frame/ModalFrame';
import useAxios from 'hooks/useAxios';
import { Contacts, ProjectContacts } from 'types/api';

export interface IProjectContactsModal {
  projectId: string;
  isOpen: boolean;
  onClose: () => void;
}

interface IProjectContactsForm {
  ProjectContacts: {
    ProjectContactIdentifier: string;
    Role: string;
  }[];
}

export default function ProjectContactsModal({
  isOpen, onClose, projectId,
}: IProjectContactsModal): JSX.Element {
  const [projectContactRolesRequest, sendProjectContactRolesRequest] = useAxios<string[]>('/api/projects/roles', true);
  const [projectContactsRequest, sendProjectContactsRequest] = useAxios<{ ProjectContact: ProjectContacts, Contact: Contacts }[]>(`/api/contacts/project/${projectId}`, true);

  const toast = useToast();

  const methods = useForm<IProjectContactsForm>();
  const { register, formState, handleSubmit } = methods;

  useAsync(async () => {
    if (!isOpen) return;
    void sendProjectContactRolesRequest();
    void sendProjectContactsRequest();
  }, [isOpen]);

  async function handleClose(): Promise<void> {
    onClose();
  }

  async function onSubmit(data: IProjectContactsForm): Promise<void> {
    const fmt: Record<string, string> = {};
    for (const person of data.ProjectContacts) {
      fmt[person.ProjectContactIdentifier] = person.Role;
    }

    await studioServerApi.PUT('/api/projects/contact-roles', {
      body: fmt,
    });

    toast({
      title: 'Success',
      description: 'Project members updated',
      status: 'success',
      duration: 5000,
      position: 'top',
      isClosable: true,
    });
  }

  return (
    <ModalFrame
      header={<BasicModalHeader title='Project Contacts' />}
      isOpen={isOpen}
      onClose={handleClose}
      size='2xl'
      hasCloseButton
    >
      <Flex
        direction='column'
        gap='8px'
      >
        <AsyncWrapper requests={[projectContactRolesRequest, projectContactsRequest]}>
          <FormProvider {...methods}>
            <form>
              <Flex direction='column' gap='20px'>
                {projectContactsRequest.data?.map((person, index) => (
                  <Flex key={person.Contact.ContactIdentifier} align='center'>
                    <Box minWidth='200px'>
                      <Text isTruncated>{person.Contact.FirstName} {person.Contact.LastName}</Text>
                    </Box>
                    <Spacer />
                    <input
                      type='hidden'
                      {...register(`ProjectContacts.${index}.ProjectContactIdentifier`)}
                      value={person.ProjectContact.ProjectContactIdentifier}
                    />
                    <Select defaultValue={person.ProjectContact.Role ?? undefined} {...register(`ProjectContacts.${index}.Role`)}>
                      {projectContactRolesRequest.data?.map((role) => (
                        <option key={role} value={role}>{role.replace('Project', '')}</option>
                      ))}
                    </Select>
                  </Flex>
                ))}
                <AsyncButton
                  formState={formState}
                  spinAfterPromiseResolvedSuccess={false}
                  maxWidth='200px'
                  type='submit'
                  onClick={handleSubmit(onSubmit)}
                >
                  Save Changes
                </AsyncButton>
              </Flex>
            </form>
          </FormProvider>
        </AsyncWrapper>
      </Flex>
    </ModalFrame>
  );
}
