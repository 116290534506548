import {
  Avatar, AvatarGroup, Flex, Text, Tooltip,
} from '@chakra-ui/react';
import { When } from 'react-if';

import { transferServerApi } from 'api/transfersServerApi';
import ArtistIcon from 'assets/icons/artist.svg';
import clientIcon from 'assets/icons/client.svg';
import DateIcon from 'assets/icons/date.svg';
import ProjectIcon from 'assets/icons/project.svg';
import SizeIcon from 'assets/icons/size.svg';
import TeamIcon from 'assets/icons/team.svg';
import TransferIcon from 'assets/icons/transfer.svg';
import DateComponent from 'components/DateComponent';
import { InfoSet } from 'components/Infoset';
import Overview, { OverviewProps } from 'components/Overview';
import QuerySuspense from 'components/QuerySuspense';
import { useApiQuery } from 'hooks/useApiQuery';
import { filesSizeTags } from 'utils/formatter';

type HiddenInfoSets = {
  header?: boolean,
  projectName?: boolean,
  artist?: boolean,
  team?: boolean,
  date?: boolean,
  transferCount?: boolean,
  totalSize?: boolean,
  client?: boolean,

};

const defaultOverviewProps: OverviewProps = {
  header: 'Project Overview',
  background: 'teal.1000',
  before: {
    background: 'teal.600',
  },
};

const defaultHiddenInfoSets = {
  header: false,
  projectName: false,
  artist: false,
  team: false,
  date: false,
  transferCount: false,
  totalSize: false,
};

export function ProjectOverview({
  projectId,
  overviewProps,
  hidden,
}: {
  projectId?: string | number,
  overviewProps?: OverviewProps,
  hidden?: HiddenInfoSets
}): JSX.Element {
  const projectOverviewQuery = useApiQuery({
    queryKey: [`overview-${projectId}`],
    queryFn: () => transferServerApi.GET('/api-v2/projects/{projectNumber}/overview', {
      params: {
        path: {
          projectNumber: Number(projectId),
        },
      },
    }),
  });
  const hide = { ...defaultHiddenInfoSets, ...hidden };

  return (
    <Overview
      {...defaultOverviewProps}
      {...overviewProps}
      hideHeader={hide.header}
    >
      <QuerySuspense mountChildrenDiscrete queries={[projectOverviewQuery.queryResult]}>
        <When condition={!hide.projectName}>
          <InfoSet
            label='Project'
            icon={ProjectIcon}
            value={(
              <Text as='span' whiteSpace='pre-wrap'>
                <Text as='span'>{`${projectOverviewQuery.apiResult?.data?.projectNumber}: ${projectOverviewQuery.apiResult?.data?.name}`}</Text>
              </Text>
            )}
          />
        </When>
        <When condition={!hide.client}>
          <InfoSet
            label='Client'
            icon={clientIcon}
            value={`${projectOverviewQuery.apiResult?.data?.clientNumber}: ${projectOverviewQuery.apiResult?.data?.clientName}`}
          />
        </When>
        <When condition={!hide.artist}>
          <InfoSet
            label='Artist'
            icon={ArtistIcon}
            value={(
              <Text
                as='span'
                maxW='240px'
                whiteSpace='nowrap'
                textOverflow='ellipsis'
                overflow='hidden'
              >{projectOverviewQuery.apiResult?.data?.artist}
              </Text>
            )}
          />
        </When>
        <When condition={!hide.team}>
          {/* <InfoSet
            label='Team'
            icon={TeamIcon}
            condition={projectOverviewQuery.apiResult?.data?.team.name || projectOverviewQuery.apiResult?.data?.team.members?.length}
            value={(
              <Flex dir='row' gap='2'>
                <When condition={projectOverviewQuery.apiResult?.data?.team.name}>
                  <Tooltip label={projectOverviewQuery.apiResult?.data?.team.name} hasArrow arrowSize={15}>
                    <Avatar size='sm' name={projectOverviewQuery.apiResult?.data?.team.name} />
                  </Tooltip>
                </When>
                <When condition={projectOverviewQuery.apiResult?.data?.team.members?.length}>
                  <Tooltip label={projectOverviewQuery.apiResult?.data?.team.members?.map((m: { name: string }, i: number) => m.name).join(', ')} hasArrow arrowSize={15}>
                    <AvatarGroup size='sm' max={2}>
                      {projectOverviewQuery.apiResult?.data?.team.members.map((member) => (
                        <Avatar name={member.name} borderColor='transparent' key={member.name} />
                      ))}
                    </AvatarGroup>
                  </Tooltip>
                </When>
              </Flex>
            )}
          /> */}
        </When>
        <When condition={!hide.date}>
          <InfoSet
            label='Date'
            icon={DateIcon}
            // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
            value={projectOverviewQuery.apiResult?.data?.date ? <DateComponent date={projectOverviewQuery.apiResult?.data?.date} /> : undefined}
          />
        </When>
        <When condition={!hide.transferCount}>
          <InfoSet
            label='Transfer Count'
            icon={TransferIcon}
            value={projectOverviewQuery.apiResult?.data?.stats.transferCount}
          />
        </When>
        <When condition={!hide.totalSize}>
          <InfoSet
            label='Total Size'
            icon={SizeIcon}
            value={
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              projectOverviewQuery.apiResult?.data?.stats.sizeTotal !== undefined
              || projectOverviewQuery.apiResult?.data?.stats.fileCount !== undefined
                ? filesSizeTags(projectOverviewQuery.apiResult?.data?.stats.sizeTotal, projectOverviewQuery.apiResult?.data?.stats.fileCount)
                : undefined
            }
          />
        </When>
      </QuerySuspense>
    </Overview>
  );
}
