import {
  CodeResponse, TokenResponse, hasGrantedAllScopesGoogle, useGoogleLogin,
} from '@react-oauth/google';
import { useState } from 'react';
import { useAsync } from 'react-use';

import { studioServerApi } from 'api/studioServerApi';

export default function useGoogleConnect(): [() => void, CodeResponse | undefined] {
  const [codeResponse, setCodeResponse] = useState<CodeResponse>();

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => {
      setCodeResponse(response);
      const hasAccess = hasGrantedAllScopesGoogle(
        response as unknown as TokenResponse,
        'https://www.googleapis.com/auth/tasks',
      );
      // TODO: Handle user access if rejected
      if (hasAccess) {
        console.log('has access');
      }
    },
    onError: (response:any) => {
      console.error(response);
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/tasks',
  });

  useAsync(async () => {
    if (codeResponse?.code) {
      await studioServerApi.PUT('/api/contacts/google-auth-code', {
        data: {
          GoogleAuthCode: codeResponse.code,
        },
      });
      return codeResponse.code;
    }
  }, [codeResponse?.code]);

  return [googleLogin, codeResponse];
}
