import {
  Flex, Img, Spacer, Text,
} from '@chakra-ui/react';
import { format } from 'date-fns';

import DownloadIcon from 'assets/icons/download.svg';
import ButtonWithTooltip from 'components/ButtonWithTooltip';

type InvoiceDisplayProps = {
  projectNumber: number;
  projectName: string;
  date: string;
  isPaid: boolean;
  artistName?: string;
  price: number;
  pdfDownloadId: string;
  invoiceNumber?: string | null
};

export function InvoiceDisplay({
  projectNumber,
  projectName,
  invoiceNumber,
  date,
  isPaid,
  artistName,
  price,
  pdfDownloadId,
}: InvoiceDisplayProps): JSX.Element {
  const downloadPdf = (id: string): void => {
    const url = `/api/downloads/project-invoice/${id}`;
    window.open(url, '_blank');
  };

  return (
    <Flex
      gap='28px'
      borderRadius='12px'
      borderColor='gray.400'
      borderWidth='1px'
      padding='15px 15px 15px 15px'
      maxWidth='400px'
      height='130'
    >
      <Flex direction='column'>
        <Flex direction='column'>
          <Flex alignItems='center'>
            <Text>{invoiceNumber}</Text>
            <Text color='green.400' ml='5px'>Paid</Text>
          </Flex>
        </Flex>
        <Flex direction='column' mt='5'>
          <Text mb='5px'>{projectName}</Text>
          <Text>{artistName}</Text>
        </Flex>
      </Flex>
      <Spacer />
      <Flex direction='column' alignItems='flex-end'>
        <Text>{format(new Date(date), 'MMM d, yyyy')}</Text>
        <Flex alignItems='center' direction='column'>
          <Flex direction='row'>
            <Text mt='2px'>${price}</Text>
          </Flex>
          <Spacer />
          <ButtonWithTooltip
            onClick={() => downloadPdf(pdfDownloadId)}
            title='Download PDF'
            icon={<Img src={DownloadIcon} display='flex' width='30px' mt='6' />}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
