import { createStandaloneToast } from '@chakra-ui/react';
import { Middleware } from 'openapi-fetch';

import { getAuthHeader } from './auth';
import { env } from 'env';
import { StudioServerApiError } from 'StudioError';

function getErrorMessage(resBody?: any | null): string {
  let errorMessage = 'Contact admin';

  if (typeof resBody === 'object') {
    errorMessage = resBody?.Message || resBody?.message;
  }

  if (typeof resBody === 'string') {
    errorMessage = resBody;
  }

  return errorMessage;
}

export const { toast } = createStandaloneToast({
  defaultOptions: {
    status: 'error',
    position: 'top',
    isClosable: true,
  },
});

export const authMiddleware: Middleware = {
  async onRequest({ request }) {
    const authHeader = await getAuthHeader();
    request.headers.set('Authorization', authHeader);
    request.headers.set('X-AccountNumber', '1');
    request.headers.set('X-APP-VERSION', env.REACT_APP_VERSION);
    request.headers.set('X-APP-SHA', env.REACT_APP_SHA);
    return request;
  },
};

export const errorMiddleware: Middleware & { method: string } = {
  // store method per req
  method: '',
  async onRequest({ request }) {
    this.method = request.method;
    return request;
  },
  async onResponse({ response }) {
    if (response.status < 400) {
      return response;
    }
    const resBody = await response.json();
    const errorMessage = getErrorMessage(resBody);

    const error = new StudioServerApiError(
      response.status,
      response.url,
      this.method,
      errorMessage,
    );

    void toast({
      title: response.statusText,
      description: errorMessage,
    });

    throw error;
  },
};
