/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import {
  Box, Flex, Heading, Image, Link, Stack, Switch, Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { transferServerApi } from 'api/transfersServerApi';
import PhoneIcon from 'assets/icons/chat-icon-gray.svg';
import QuerySuspense from 'components/QuerySuspense';
import { useApiQuery } from 'hooks/useApiQuery';

export default function SmsOptIn(): JSX.Element {
  const [hideSmsOptInAlert, setHideSmsOptInAlert] = useLocalStorage<boolean>('hide-sms-opt-in-alert');
  const contactQuery = useApiQuery({
    queryKey: [],
    queryFn: () => transferServerApi.GET('/api-v2/contacts'),
  });
  const phoneNumber = contactQuery.apiResult?.data?.MobileTelephone || 'N/A';
  const smsEnrollmentQuery = useApiQuery({
    queryKey: [phoneNumber],
    queryFn: () => transferServerApi.GET('/api-v2/sms/enroll'),
    enabled: !!phoneNumber,
  });

  const [isSwitchOn, setIsSwitchOn] = useState(!!smsEnrollmentQuery.apiResult?.data?.OptIn);

  useEffect(() => {
    setIsSwitchOn(!!smsEnrollmentQuery.apiResult?.data?.OptIn);
  }, [smsEnrollmentQuery.apiResult?.data?.OptIn]);

  const onSmsEnabledChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { checked } = e.target;
    setIsSwitchOn(checked);
    const oldHideSmsOptInAlert = hideSmsOptInAlert;
    setHideSmsOptInAlert(true);
    await transferServerApi.POST('/api-v2/sms/enroll', {
      body: {
        OptIn: checked,
      },
    });
    if (!oldHideSmsOptInAlert) window.location.reload();
  };
  return (
    <QuerySuspense queries={[smsEnrollmentQuery.queryResult, contactQuery.queryResult]}>
      <Box marginLeft='100' border='gray.800'>
        <Stack marginBottom='50px' minW='1000px'>
          <Heading fontSize='4xl' as='b'>Subscriptions Settings</Heading>
          {/* <Text>To make changes to your number, please contact Sterling Sound.</Text> */}
        </Stack>
        <Box bg='gray.800' borderRadius='15' p='35px 45px' w='75%' minWidth='920px' marginBottom='70px'>
          <Flex direction='row' gap='4%'>
            <Flex dir='row' gap='5' alignItems='center'>
              <Flex direction='column'>
                <Flex gap='7px' alignItems='center'>
                  <Image src={PhoneIcon} h='23px' />
                  <Heading fontSize='22px' color='gray.400' marginBottom='7px'>SMS Notifications</Heading>
                </Flex>
                <Text marginBottom='10px'>You agree to receive automated text message updates regarding your mastering sessions with Sterling Sound at:</Text>
                <Flex gap='10px' alignItems='center'>
                  <div style={{ borderLeft: '3px solid #718096', height: '20px', borderRadius: '20%' }} />
                  <Text fontSize='22px'>{ phoneNumber }</Text>
                </Flex>
                <Text marginTop='15px' marginBottom='10px'>
                  If you require changes to your number, please contact Sterling Sound. Visit our
                  <Link color='gray.500'>Privacy Policy</Link>
                </Text>
              </Flex>
            </Flex>
            <Flex gap='7px' marginTop='7' minWidth='200px' direction='column'>
              <Text marginBottom='13px'>If you require changes to your number, please contact Sterling Sound.</Text>
              <Flex gap='8px' alignItems='center'>
                <Switch
                  size='lg'
                  defaultChecked={!!smsEnrollmentQuery.apiResult?.data?.OptIn}
                  onChange={onSmsEnabledChange}
                  sx={{
                    '& .chakra-switch__track': {
                      bg: isSwitchOn ? 'gold.1000' : '',
                    },
                  }}
                />
                <Text>SMS Transfer notifications</Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </QuerySuspense>
  );
}
