import {
  FormControl, Input, Stack, Text, useToast,
} from '@chakra-ui/react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useWizard } from 'react-use-wizard';

import { ISelectedContactRoles, RoleSelection } from '../components/RoleSelection';
import { useAddContact } from '../hooks/useAddContact';
import useCreateProjectContact from '../hooks/useCreateProjectContact';
import { studioServerApi } from 'api/studioServerApi';
import { Contact } from 'api/studioServerTypes';
import AsyncButton from 'components/form/AsyncButton';
import LabelInput from 'components/form/LabelInput';
import { validateEmail } from 'components/form/validators';

type IAddExistingContact = ISelectedContactRoles & {
  emailAddress: string;
};

export function AddExistingContactStep(): JSX.Element {
  const { projectIdentifier, setContactInfo } = useAddContact();

  const navigate = useNavigate();
  const formMethods = useForm<IAddExistingContact>();
  const {
    handleSubmit, register, formState, control, getValues,
  } = formMethods;

  const { nextStep } = useWizard();
  const { addProjectContact, addRolesToContact } = useCreateProjectContact({ projectIdentifier: projectIdentifier! });
  const toast = useToast();

  const navigateOverview = (): void => void navigate(`/booking/sessions/${projectIdentifier}/overview`);

  const onSubmit = async (form: IAddExistingContact): Promise<void> => {
    const { emailAddress, ...roles } = form;
    setContactInfo({
      email: emailAddress.trim().toLocaleLowerCase(),
      isUploader: roles.isUploader,
      isReceiver: roles.isReceiver,
    });
    const response = await studioServerApi.GET('/api/contacts/email', {
      params: {
        query: {
          emailAddress: emailAddress.trim().toLocaleLowerCase(),
        },
      },
    });
    const existingContact = response.data?.find((contact: Contact) => contact.Email === emailAddress && !contact.DoNotUse);
    if (existingContact) {
      await addProjectContact(existingContact.ContactIdentifier!);
      await addRolesToContact(existingContact.ContactIdentifier!, roles);
      toast({
        title: 'Contact added to Project',
        status: 'success',
      });

      navigateOverview();
    }
    void nextStep();
  };

  return (
    <FormControl>
      <FormProvider {...formMethods}>
        <form>
          <Stack spacing='30px'>
            <Controller
              name='emailAddress'
              control={control}
              rules={{ required: 'Email is required', validate: validateEmail }}
              render={({ field, fieldState }) => (
                <LabelInput error={fieldState.error} label='Email'>
                  <Input {...field} placeholder='Email' type='email' isInvalid={!!fieldState.error} />
                </LabelInput>
              )}
            />
            <Text fontSize='18px'>Select their Role(s)</Text>
            <RoleSelection register={register} getValues={getValues} formState={formState} />
            <AsyncButton
              formState={formState}
              type='submit'
              onClick={handleSubmit(onSubmit)}
            >Add
            </AsyncButton>
          </Stack>
        </form>
      </FormProvider>
    </FormControl>
  );
}
