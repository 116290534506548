import {
  Avatar, Box, Button, Img, Tooltip,
} from '@chakra-ui/react';
import { type Row, createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { When } from 'react-if';
import { useNavigate } from 'react-router-dom';
import { useGate } from 'statsig-react';

import CreateProjectIcon from 'assets/icons/createproject.svg';
import ProjectIcon from 'assets/icons/project.svg';
import UploadArrowIcon from 'assets/icons/upload-arrow-icon.svg';
import AuthorizeDisplay from 'components/AuthorizeDisplay';
import ButtonWithTooltip from 'components/ButtonWithTooltip';
import DateComponent from 'components/DateComponent';
import CreateProjectModal from 'components/modals/CreateProjectModal';
import ProjectName from 'components/ProjectName';
import {
  ArtistHeader,
  DateHeader,
  FilesHeader,
  ProjectHeader,
  TeamHeader,
  TransferHeader,
} from 'components/table/headers';
import StudiumTable from 'components/table/StudiumTable';
import { Roles } from 'contexts/user/claims';
import useUserContext from 'hooks/useUserContext';
import { StatsigGates } from 'StatsigGates';
import { ProjectCTRowDto } from 'types/api';
import { filesSizeTags } from 'utils/formatter';

const emptyTableState = {
  message: 'You have no projects yet',
  icon: ProjectIcon,
};

export default function ProjectsTable(): JSX.Element {
  const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState(false);

  const navigate = useNavigate();

  const { user } = useUserContext();

  const columnHelper = createColumnHelper<ProjectCTRowDto>();

  const projectTableColumns = [
    columnHelper.accessor('artist', {
      cell: (info) => info.getValue(),
      header: ArtistHeader,
    }),
    columnHelper.accessor('project', {
      cell: (info) => ProjectName(info.getValue()),
      header: ProjectHeader,
    }),
    columnHelper.display({
      id: 'resource',
      header: TeamHeader,
      cell: ({ row }) => (() => (
        <Tooltip label={row.original.resource || 'Unknown'}>
          <Avatar size='sm' name={row.original.resource || 'unknown'} />
        </Tooltip>
      ))(),
    }),
    columnHelper.accessor('stats.transferCount', {
      cell: (info) => info.getValue(),
      header: TransferHeader,
    }),
    columnHelper.accessor('stats', {
      cell: (info) => filesSizeTags(info.getValue().sizeTotal, info.getValue().fileCount),
      header: FilesHeader,
    }),
    columnHelper.accessor('date', {
      cell: (info) => (() => (
        <DateComponent date={info.getValue()} />
      ))(),
      header: DateHeader,
    }),
    columnHelper.display({
      id: 'Actions',
      header: '',
      cell: ({ row }) => (() => (
        <ButtonWithTooltip
          onClick={() => navigate(`/transfers/transfer-upload/${row.original.project.id}`)}
          title='Upload'
          icon={<Img w='20px' h='15px' src={UploadArrowIcon} />}
        />
      ))(),
    }),
  ];

  function onRowClick(row: Row<ProjectCTRowDto>): void {
    navigate(`/transfers/projects/${row.original.project.id}/transfers`);
  }

  const createProjectEnabled = useGate(StatsigGates.CREATE_PROJECT_MODAL).value;
  return (
    <Box>
      <When condition={createProjectEnabled}>
        <AuthorizeDisplay role={Roles.ProjectCreator}>
          <Button padding='25px' mt='14px' height='54px' onClick={() => setIsCreateProjectModalOpen(true)}>
            <Img src={CreateProjectIcon} marginRight='10px' />
            Create Project
          </Button>
        </AuthorizeDisplay>
        <When condition={isCreateProjectModalOpen}>
          <CreateProjectModal
            isOpen={isCreateProjectModalOpen}
            onClose={() => setIsCreateProjectModalOpen(false)}
          />
        </When>
      </When>
      <StudiumTable
        columns={projectTableColumns}
        apiPath='/file-api/projects'
        handleRowClick={onRowClick}
        emptyTableState={emptyTableState}
        queryKeys={['project-table']}
      />
    </Box>
  );
}
