import { Box, Flex, Text } from '@chakra-ui/react';
import { Else, If, Then } from 'react-if';

import { InvoiceDisplay } from './components/InvoiceDisplay';
import { studioServerApi } from 'api/studioServerApi';
import QuerySuspense from 'components/QuerySuspense';
import { useApiQuery } from 'hooks/useApiQuery';

export function InvoiceList(): JSX.Element {
  const invoicesQuery = useApiQuery({
    queryKey: ['invoices'],
    queryFn: () => studioServerApi.GET('/api/project-invoices'),
  });

  return (
    <QuerySuspense queries={[invoicesQuery.queryResult]}>
      <Box mt='50px'>
        <Flex direction='column' gap='25px'>
          <If condition={invoicesQuery.apiResult?.data?.length === 0}>
            <Then>
              <Text
                textAlign='center'
                mt='100px'
              >You have no invoices
              </Text>
            </Then>
            <Else>
              {invoicesQuery.apiResult?.data?.map((invoice) => (
                <InvoiceDisplay
                  key={invoice.ProjectInvoiceIdentifier}
                  projectNumber={invoice.ProjectNumber!}
                  projectName={invoice.ProjectName || 'N/A'}
                  invoiceNumber={invoice.InvoiceNumber}
                  date={invoice.InvoiceDate!}
                  isPaid={invoice.Paid!}
                  artistName={invoice.ArtistName ?? undefined}
                  price={invoice.Amount!}
                  pdfDownloadId={`${invoice.ProjectInvoiceIdentifier!}`}
                />
              ))}
            </Else>
          </If>
        </Flex>
      </Box>
    </QuerySuspense>
  );
}
