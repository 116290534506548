import {
  Box,
  Button, Flex, FormControl, FormErrorMessage, Heading, Image, Input, InputGroup, InputLeftElement, Link, Text,
  useToast,
} from '@chakra-ui/react';
import {
  FormProvider, UseFormSetError, useForm,
} from 'react-hook-form';
import { When } from 'react-if';
import { Link as RouterLink } from 'react-router-dom';
import { useEffectOnce, useSearchParam } from 'react-use';
import { useGate } from 'statsig-react';

import { signInWithGooglePopup, signInWithMicrosoftPopup } from 'api/auth';
import ActivateIcon from 'assets/icons/activate.svg';
import EmailIcon from 'assets/icons/email.svg';
import GoogleIcon from 'assets/icons/google.svg';
import LockIcon from 'assets/icons/lock.svg';
import MicrosoftIcon from 'assets/icons/microsoft.svg';
import RightArrowIcon from 'assets/icons/right-arrow-black.svg';
import WarningIcon from 'assets/icons/warning.svg';
import AsyncButton from 'components/form/AsyncButton';
import { StatsigGates } from 'StatsigGates';

export interface ILogin {
  email: string,
  password: string
}

export function CredentialsForm({ submitCallback, redirectLocation }: {
  submitCallback?: (data: ILogin, setError: UseFormSetError<ILogin>) => Promise<void>;
  redirectLocation: string;
}): JSX.Element {
  const formMethods = useForm<ILogin>();
  const toast = useToast();
  const signInWithGoogle = useGate(StatsigGates.SIGN_IN_WITH_GOOGLE).value;
  const signInWithMicrosoft = useGate(StatsigGates.SIGN_IN_WITH_MICROSOFT).value;
  const hasActiveOauth = signInWithGoogle || signInWithMicrosoft;
  const {
    register, handleSubmit, formState, setError,
  } = formMethods;
  const { errors, isValid } = formState;

  const emailAddress = useSearchParam('email');

  useEffectOnce(() => {
    if (emailAddress) {
      formMethods.setValue('email', emailAddress);
      const passwordField = document.getElementById('password');
      if (passwordField) {
        passwordField.focus();
      }
    }
  });

  async function onSubmit(data: ILogin): Promise<void> {
    if (data && isValid && submitCallback) {
      await submitCallback({ email: data.email.trim().toLocaleLowerCase(), password: data.password }, setError);
    }
  }

  async function loginGoogleUser(): Promise<void> {
    try {
      await signInWithGooglePopup();
      window.location.href = redirectLocation;
    } catch (e: any) {
      toast({
        title: `Failed to login: ${e.message}`,
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
      throw new Error(e);
    }
  }

  async function loginMicrosoftUser(): Promise<void> {
    try {
      await signInWithMicrosoftPopup();
      window.location.href = redirectLocation;
    } catch (e: any) {
      toast({
        title: `Failed to login: ${e.message}`,
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
      throw new Error(e);
    }
  }

  return (
    <Flex direction='column' align='center' w='300px' mx='auto'>
      <Heading fontSize='24px' fontWeight='600' mb='30px'>Sign in</Heading>
      <FormControl isInvalid={!!errors.password?.message || !!errors.email?.message}>
        <FormProvider {...formMethods}>
          <form>
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <Image src={EmailIcon} w='10px' h='10px' />
              </InputLeftElement>
              <Input
                id='email'
                mb='10px'
                autoFocus
                autoComplete='email'
                placeholder='Email'
                {...register('email', { required: 'Email is required' })}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <Image src={LockIcon} w='10px' h='10px' />
              </InputLeftElement>
              <Input
                id='password'
                autoComplete='password'
                placeholder='Password'
                type='password'
                {...register('password', { required: 'Password is required' })}
              />
            </InputGroup>
            <When condition={!!errors.email}>
              <FormErrorMessage mt='10px' gap='10px'>
                <Image src={WarningIcon} w='11px' h='10px' />
                <Text pt='5px' textColor='gold.1000'>{errors.email?.message} </Text>
              </FormErrorMessage>
            </When>
            <When condition={!!errors.password}>
              <FormErrorMessage mt='10px' gap='10px'>
                <Image src={WarningIcon} w='11px' h='10px' />
                <Text pt='5px' textColor='gold.1000'>{errors.password?.message} </Text>
              </FormErrorMessage>
            </When>
            <AsyncButton
              formState={formState}
              backgroundColor='gold.1000'
              color='black'
              variant='login'
              type='submit'
              onClick={handleSubmit(onSubmit)}
            >
              Sign in
              <Image w='13px' h='10px' src={RightArrowIcon} />
            </AsyncButton>
          </form>
        </FormProvider>
      </FormControl>
      <When condition={hasActiveOauth}>
        <Box mt='25px' width='100%'>
          <Box display='flex' flexDir='column' gap='20px'>
            <When condition={signInWithMicrosoft}>
              <Button onClick={loginMicrosoftUser} w='100%' variant='back' bg='gray.700' leftIcon={<Image src={MicrosoftIcon} />}>Continue with Microsoft</Button>
            </When>
            <When condition={signInWithGoogle}>
              <Button onClick={loginGoogleUser} w='100%' variant='back' bg='gray.700' leftIcon={<Image src={GoogleIcon} />}>Continue with Google</Button>
            </When>
          </Box>
        </Box>
        <Flex alignItems='center' justifyContent='center' my={4} mt='45' mb='25'>
          <Box borderBottom='1px solid white' width='110px' />
          <Text mx={5}>Or</Text>
          <Box borderBottom='1px solid white' width='110px' />
        </Flex>
      </When>
      <Button as={RouterLink} to='/reset-password?actionType=activate' variant='login' backgroundColor='gray.600'>
        Activate Account
        <Image w='15px' h='15px' src={ActivateIcon} />
      </Button>
      <Link fontWeight='400' fontSize='14px' mt='25px' as={RouterLink} to='/reset-password?actionType=reset'>
        {' '}
        Forgot your password? Click &#32;
        <Text as='span' fontWeight='bold'>
          here.
        </Text>
      </Link>
    </Flex>
  );
}
