import {
  Box, Flex, Input,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { OptionBase } from 'chakra-react-select';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useWizard } from 'react-use-wizard';

import { studioServerApi } from 'api/studioServerApi';
import type { Client, Contact } from 'api/studioServerTypes';
import FullName from 'assets/icons/fullname.svg';
import AsyncButton from 'components/form/AsyncButton';
import CountrySelector from 'components/form/CountrySelector';
import { IconInput } from 'components/form/IconInput';
import QuerySuspense from 'components/QuerySuspense';
import { useBookingContext } from 'contexts/booking/BookingContext';
import { useGeoInfo } from 'contexts/GeoInfoProvider';
import { useApiQuery } from 'hooks/useApiQuery';

interface CountryOption extends OptionBase {
  label: string;
  value: string;
}
type ClientFormValues = {
  Name: string;
  Address1: string;
  Address2: string;
  StateProvince: string;
  PostalCode: string;
  City: string;
  Country: CountryOption;
};

type ClientWithContact = { Client: Client, Contact: Contact };

export default function RegisterClient(): JSX.Element {
  const { nextStep } = useWizard();
  const { contact, setClient } = useBookingContext();

  const countriesQuery = useApiQuery({ queryKey: ['/api/countries'], queryFn: () => studioServerApi.GET('/api/countries') });
  const { userCountryCode } = useGeoInfo();
  const defaultCountryOption = useMemo(() => {
    const defaultCountry = countriesQuery.apiResult?.data?.find((country) => country.Abbreviation === userCountryCode);
    return defaultCountry ? { label: defaultCountry.Name!, value: defaultCountry.Abbreviation! } : undefined;
  }, [countriesQuery.apiResult?.data, userCountryCode]);

  const {
    handleSubmit, control, reset, formState,
  } = useForm<ClientFormValues>({
    defaultValues: {
      Country: defaultCountryOption,
    },
  });

  useEffect(() => {
    reset({ Country: defaultCountryOption });
  }, [defaultCountryOption, reset]);

  const { mutateAsync } = useMutation({
    mutationFn: (newClient: ClientWithContact) => studioServerApi.POST('/api/booking/register/client', { body: newClient }),
    onSuccess: (response) => {
      setClient({ ...response.data });
    },
  });

  const onSubmit = async (formValues: ClientFormValues): Promise<void> => {
    await mutateAsync({
      Client: {
        ...formValues,
        Name: formValues.Name.trim(),
        City: formValues.City.trim(),
        Country: formValues.Country.value,
      },
      Contact: contact!,
    });
    await nextStep();
  };

  return (
    <QuerySuspense queries={[countriesQuery.queryResult]}>
      <Box mt='15px'>
        <form>
          <Flex direction='column' mb={4} gap={4}>
            <Controller
              name='Name'
              control={control}
              rules={{ required: 'Company name is required' }}
              render={({ field, fieldState }) => (
                <IconInput
                  icon={FullName}
                  alt='Company Icon'
                  error={fieldState.error}
                >
                  <Input {...field} placeholder='Company name' isInvalid={!!fieldState.error} />
                </IconInput>
              )}
            />
            <Controller
              name='Address1'
              control={control}
              rules={{ required: 'Address is required' }}
              render={({ field, fieldState }) => (
                <IconInput
                  icon={FullName}
                  alt='Address Icon'
                  error={fieldState.error}
                >
                  <Input {...field} placeholder='Address (Line 1)' isInvalid={!!fieldState.error} />
                </IconInput>
              )}
            />
            <Controller
              name='Address2'
              control={control}
              render={({ field, fieldState }) => (
                <IconInput
                  icon={FullName}
                  alt='Address Icon'
                  error={fieldState.error}
                >
                  <Input {...field} placeholder='Address (Line 2)' isInvalid={!!fieldState.error} />
                </IconInput>
              )}
            />
            <Flex gap={3}>
              <Controller
                name='StateProvince'
                control={control}
                rules={{ required: 'State is required' }}
                render={({ field, fieldState }) => (
                  <IconInput
                    icon={FullName}
                    alt='State Icon'
                    error={fieldState.error}
                  >
                    <Input {...field} placeholder='State' isInvalid={!!fieldState.error} />
                  </IconInput>
                )}
              />
              <Controller
                name='PostalCode'
                control={control}
                rules={{ required: 'Postal code is required' }}
                render={({ field, fieldState }) => (
                  <IconInput
                    icon={FullName}
                    alt='Postal Code Icon'
                    error={fieldState.error}
                  >
                    <Input {...field} placeholder='ZIP/Postal Code' isInvalid={!!fieldState.error} />
                  </IconInput>
                )}
              />
            </Flex>
            <Controller
              name='City'
              control={control}
              render={({ field, fieldState }) => (
                <IconInput
                  icon={FullName}
                  alt='City Icon'
                  error={fieldState.error}
                >
                  <Input {...field} placeholder='City' isInvalid={!!fieldState.error} />
                </IconInput>
              )}
            />
            <Controller
              name='Country'
              control={control}
              rules={{ required: 'Country is required' }}
              render={({ field, fieldState }) => (
                <CountrySelector
                  {...field}
                  error={fieldState.error}
                  options={countriesQuery.apiResult?.data?.map((country) => ({ label: country.Name!, value: country.Abbreviation! }))}
                  isSearchable
                />
              )}
            />
          </Flex>
          <AsyncButton
            formState={formState}
            type='submit'
            onClick={handleSubmit(onSubmit)}
            colorScheme='teal'
            width='100%'
            mb='15px'
          >
            Create Company
          </AsyncButton>
        </form>
      </Box>
    </QuerySuspense>
  );
}
